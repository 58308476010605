import { useCallback, useState } from "react";
import axios from "axios";
import GetApiUrl from "../getApiUrl";

export const useGetJoinNum = () => {
    const [resultGetJoinNum, setResultGetJoinNum] = useState<ResultData | undefined>();
    const [isLoadingJoinNum, setIsLoadingJoinNum] = useState<boolean>(false);
    const [isErrorGetJoinNum, setIsErrorGetJoinNum] = useState<boolean>(false);

    const assistSvr = axios.create({
        baseURL: GetApiUrl(),
    });

    const getJoinNum = useCallback(async (account_id: string) => {
        setIsLoadingJoinNum(true);
        setIsErrorGetJoinNum(false);
        // console.log(account_id);

        await assistSvr
            .get(`/passport/getJoinNum?account_id=${account_id}`)
            .then((response) => {
                // console.dir(response.data);
                // API呼び出しエラー
                if (response.status !== 200) {
                    throw new Error("API接続エラー");
                } else {
                    // 取得データを受け渡し領域にセット
                    setResultGetJoinNum(response.data);
                }
            })
            .catch((e) => {
                setIsErrorGetJoinNum(true);
                console.dir(e);
                process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
            })
            .finally(() => {
                setIsLoadingJoinNum(false);
            });
    }, []);
    return { resultGetJoinNum, getJoinNum, isLoadingJoinNum, isErrorGetJoinNum };
};

export type JoinNumData = {
    event_id: string;       // イベントID
    event_name: string;     // イベント名称
    count: string;          // カウント数
};

export type ResultData = {
    result_code: string;    // 
    message: string;        // エラーメッセージ
    result: JoinNumData[];
};
