import React, { useContext, useEffect, useRef, useState } from "react";

import css from "./Passport.module.css";
import QrGen from "./comp_QrGen";
import type { ConditionExecuteJoin } from "../../components/apiAccess/passport/useExecuteJoin";
import { UserInfoContext } from "../../components/providers/userInfoProvider";
import joinSound from "../../../../sounds/join.wav";
import { useNavigate } from "react-router-dom";
import { postExecuteJoin } from "../../components/apiAccess/passport/postExecuteJoin";
import QrReader from "./comp_QrReader";

const ChangeCameraAndQrcode = (props: any) => {
  const {
    isQrIndicatiorMode,
    eventId,
    isPortrait,
    setIsSuccessJoin,
    isCameraActive,
    qrChange,
  } = props;

  const refCameraQrArea = useRef<HTMLDivElement>(null!);
  const [scanedCode, setScanedCode] = useState<string>("");
  // ログインユーザ情報
  const { userInfo, setUserInfo } = useContext(UserInfoContext);

  const [cameraWidth, setCameraWidth] = useState<number>();
  const [cameraHeight, setCameraHeight] = useState<number>();

  const refInnerWidth = useRef<number>(0);
  const refInnerHeight = useRef<number>(0);

  // audio要素への参照
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    // console.log("ChangeCameraAndQrcode 初回実行 ");
    refInnerWidth.current = window.innerWidth;
    refInnerHeight.current = window.innerHeight;
  }, []);

  useEffect(() => {
    console.log(
      "eventId=" +
        eventId +
        ", innerWidth=" +
        refInnerWidth.current +
        ", innerHeight=" +
        refInnerHeight.current +
        ", isPortrait=" +
        isPortrait
    );

    const userAgent = window.navigator.userAgent;
    // console.log(userAgent);

    let height: number;
    if (userAgent.match(/Android|iPhone OS/)) {
      // console.log("デバイス: Android or iPhone");
      height = Math.floor(refInnerWidth.current * (3 / 4));
      setCameraWidth(height);
      setCameraHeight(refInnerWidth.current);
    } else {
      // console.log("デバイス: Mac or Windows");
      setCameraWidth(refInnerWidth.current);
      height = Math.floor(refInnerWidth.current * (3 / 4));
      setCameraHeight(height);
    }
    refCameraQrArea.current.style.height = height + "px";
    // console.log("height=" + height);
  }, [isPortrait]);

  // 効果音を再生する関数
  const playSound = () => {
    if (audioRef.current) {
      audioRef.current.play().catch((err) => {
        // ブラウザの自動再生制限等でエラーが出た場合の出力
        console.error("ジョイン音再生エラー:", err);
      });
    }
  };

  // マイ・ライフ・ログへの遷移設定
  const navigate = useNavigate();
  const loginMyLifeLog = () => {
    if (userInfo !== null) {
      const query =
        `?id_kind=${userInfo.id_kind}` +
        `&id=${userInfo.id}` +
        `&nickname=${userInfo.nickname}` +
        `&mail=${userInfo.mail}` +
        `&version=${userInfo.version}` +
        `&client_id=${userInfo.client_id}`;

      navigate(process.env.REACT_APP_PATH_PREFIX + "myLifeLog" + query);
    }
  };

  // QRコードを読み込んだとき
  useEffect(() => {
    console.log("scaned QR code = " + scanedCode);

    if (scanedCode === undefined || scanedCode === null || scanedCode === "")
      return;

    const condition: ConditionExecuteJoin = {
      account_id: userInfo?.id!,
      event_id: eventId,
      qr_read_code: scanedCode,
    };
    // 読み込んだQRコード値をクリア
    setScanedCode("");

    postExecuteJoin(condition).then((response: any) => {
      if (response.data.result_code === "0") {
        // console.log("Join成功");
        // 「じょいん!」音を再生
        playSound();

        // Join成功フラグを立てる（=>ジョイン数取得）
        setTimeout(() => {
          setIsSuccessJoin(true);
        }, 1000);

        // マイ・ライフ・ログへの遷移
        // setTimeout(() => loginMyLifeLog(), 1000);
      } else {
        console.log(response.data.message);
      }
    });
  }, [scanedCode]);

  return (
    <>
      <div className={css.content_inner} ref={refCameraQrArea}>
        {!isQrIndicatiorMode && eventId !== "" && isCameraActive && (
          <div className={css.qr_reader_camera}>
            <QrReader
              cameraWidth={cameraWidth}
              cameraHeight={cameraHeight}
              scanedCode={scanedCode}
              setScanedCode={setScanedCode}
            />
          </div>
        )}
        {!isQrIndicatiorMode && eventId !== "" && !isCameraActive && (
          <div className={css.qr_reader_camera}></div>
        )}
        {isQrIndicatiorMode && eventId !== "" && (
          <div className={css.qr_code_border}>
            <QrGen eventId={eventId} qrChange={qrChange} />
          </div>
        )}
        <audio ref={audioRef} src={joinSound} preload="auto" />
      </div>
    </>
  );
};

export default ChangeCameraAndQrcode;
