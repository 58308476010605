import { useCallback, useState } from "react";
import axios from "axios";
import GetApiUrl from "../getApiUrl";

export const useGetProfile = () => {
    const [resultGetProfile, setResultGetProfile] = useState<ResultData | undefined>();
    const [isLoadingProfile, setIsLoadingProfile] = useState<boolean>(false);
    const [isErrorGetProrile, setIsErrorGetProfile] = useState<boolean>(false);

    const assistSvr = axios.create({
        baseURL: GetApiUrl(),
    });

    const getProfile = useCallback(async (user_id: string) => {
        // console.log(user_id);
        setIsLoadingProfile(true);
        setIsErrorGetProfile(false);

        await assistSvr
            .get(`/profile/getProfile?user_id=${user_id}`)
            .then((response) => {
                // console.dir(response.data);
                setResultGetProfile(response.data);
            })
            .catch((e) => {
                setIsErrorGetProfile(true);
                console.dir(e);
                process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
            })
            .finally(() => {
                setIsLoadingProfile(false);
            });
    }, []);
    return { resultGetProfile, getProfile, isLoadingProfile, isErrorGetProrile };
};

export type ProfiledData = {
    user_id: string;        // 対象のめぶくID
    introduction: string;   // 対象の自己紹介
    id_type: string;        // ID種別
    role_id: string;        // アクティベーションコード用途
    nickname: string;       // ニックネーム
    mail_address: string;   // メールアドレス
    version: string;        // めぶくネイティブアプリのバージョン
    client_id: string;      // クライアントID
};

export type ResultData = {
    result_code: string;    // 
    message: string;        // エラーメッセージ
    result: ProfiledData;
};
