import { useCallback, useState } from "react";
import axios from "axios";
import GetApiUrl from "../getApiUrl";

export const useSetProfile = () => {
    const [resultSetProfile, setResultSetProfile] = useState<ResultData | undefined>();
    const [isSendingProfile, setIsSendingProfile] = useState<boolean>(false);
    const [isErrorSetProfile, setIsErrorSetProfile] = useState<boolean>(false);

    const assistSvr = axios.create({
        baseURL: GetApiUrl(),
    });

    const setProfile = useCallback(async (props: UserProfile) => {
        // console.dir(props);
        setIsSendingProfile(true);
        setIsErrorSetProfile(false);

        assistSvr
            .post(`/profile/setProfile`, {
                ...props
            })
            .then((response) => {
                // console.dir(response.data);
                setResultSetProfile(response.data);
            })
            .catch((e) => {
                setIsErrorSetProfile(true);
                console.dir(e);
                process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
            })
            .finally(() => {
                setIsSendingProfile(false);
            });
    }, []);
    return { resultSetProfile, setProfile, isSendingProfile, isErrorSetProfile };
};

export type UserProfile = {
    user_id: string;        // めぶくID
    introduction: string;   // 自己紹介
};

export type ResultData = {
    result_code: string;    // 
    message: string;        // エラーメッセージ
};
