import React, { memo } from "react";
import LifeLog from "./comp_LifeLog";

import css from "./MyLifeLog.module.css";

const MyLifeLogTable = memo((props: any) => {
  const { lifeLog } = props;

  // console.dir(lifeLog);
  return (
    <>
      {lifeLog &&
        lifeLog.length > 0 &&
        lifeLog.map((data: any, indexDate: number) => {
          return (
            <div className={css.lifelog_block} key={indexDate}>
              <div className={css.lifelog_date}>
                <span>{data.joined_at}</span>
              </div>
              <LifeLog join={data.join} indexDate={indexDate} />
            </div>
          );
        })}
    </>
  );
});

export default MyLifeLogTable;
