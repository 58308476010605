import { useCallback, useState } from "react";
import axios from "axios";
import GetApiUrl from "../getApiUrl";

// イベント一覧取得API
export const useGetEventAll = () => {
  const [resultGetEventAll, setResponseData] = useState<
    ResultData | undefined
  >();
  const [isLoadingEventAll, setIsSending] = useState<boolean>(false);
  const [isErrorGetEventAll, setIsErrorGetEventAll] = useState<boolean>(false);

  const assistSvr = axios.create({
    baseURL: GetApiUrl(),
  });

  const getEventAll = useCallback(async (props: SpecifiedEventInfo) => {
    console.dir(props);
    const { user_id, page } = props;

    setIsSending(true);
    setIsErrorGetEventAll(false);

    await assistSvr
      .get(`/event/getEventAll?user_id=${user_id}&page=${page}`)
      .then((response) => {
        console.dir(response.data);
        setResponseData(response.data);
      })
      .catch((e) => {
        setIsErrorGetEventAll(true);
        console.dir(e);
        process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
      })
      .finally(() => {
        setIsSending(false);
      });
  }, []);
  return {
    resultGetEventAll,
    getEventAll,
    isLoadingEventAll,
    isErrorGetEventAll,
  };
};

export type SpecifiedEventInfo = {
  user_id: string; // ユーザID
  page: string; // ページ番号指定
};

export type EventData = {
  event_id: string;
  charges: string;
  event_name: string;
  event_join_flg: string;
  event_icon: string;
  event_label_text: string;
  event_theme_color: string;
  event_desc_text: string;
  event_desc_date: string;
  event_desc_charge: string;
  event_desc_place: string;
  event_desc_url: string;
  event_begin_date: string;
  event_end_date: string;
  event_joining_begin: string;
  event_joining_end: string;
};

export type ResultData = {
  result_code: string; //
  message: string; // エラーメッセージ
  result: EventData[];
  count: string;
  page: string;
  page_total: string;
};
