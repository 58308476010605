import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import backArrow from "../../../../img/backArrow.svg";
import iconTabClose from "../../../../img/icon_tab_close.svg";
import iconTabMatch from "../../../../img/icon_tab_match.svg";
import iconTabOpen from "../../../../img/icon_tab_open.svg";
import { ModeFirst, ModeSecond } from "../commonTypes/types";
import { UserInfoContext } from "../providers/userInfoProvider";
import css from "./AssistTitle.module.css";

const AssistTitle = (props: any) => {
  const { modeFirst, setModeFirst, modeSecond, setModeSecond } = props;

  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);

  const navigate = useNavigate();

  // 戻るがタップされたとき
  const handlerOnClickBackArrow = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    // console.log("called: handlerOnClickBackArrow");

    if (modeSecond.code === "DETAIL") {
      setModeSecond((prev: ModeSecond) => ({ ...prev, code: "LIST" }));
    } else if (modeSecond.code === "EDIT") {
      setModeSecond((prev: ModeSecond) => ({ ...prev, code: "DETAIL" }));
    } else if (modeSecond.code === "OFFER") {
      setModeSecond((prev: ModeSecond) => ({
        ...prev,
        code: modeSecond.fromCode,
        selectedId: modeSecond.fromSelectedId,
      }));
    } else if (modeSecond.code === "LIST") {
      navigate(process.env.REACT_APP_PATH_PREFIX + "mainMenu", {
        state: {
          myId: userInfo?.id,
          recordId: modeSecond.fromSelectedId,
          yourId: e.currentTarget.dataset.yourid,
          fromPage: process.env.REACT_APP_PATH_PREFIX + "myAssistList",
        },
      });
    }
  };

  return (
    <>
      <div className={css.title_wrap}>
        <div
          className={`${css.pageTitle} ${
            modeFirst.class === "ASSIST"
              ? css["pageTitle_color-list"]
              : css["pageTitle_color-myrequest"]
          }`}
        >
          <div
            className={`${css.backArrow} ${css.clickable}`}
            onClick={handlerOnClickBackArrow}
          >
            <img src={backArrow} width="23" alt="" />
          </div>
          {modeFirst.class === "ASSIST"
            ? "自分の助け隊一覧"
            : "自分のありが隊一覧"}
        </div>
        <div className={css.headerTab}>
          <ul>
            <li
              className={`${css["headerTab_menuIcon"]} ${
                modeFirst.code === "OPEN" ? css.active : ""
              }`}
              onClick={() => {
                setModeFirst((prev: ModeFirst) => ({ ...prev, code: "OPEN" }));
                setModeSecond((prev: ModeSecond) => ({
                  ...prev,
                  code: "LIST",
                  fromCode: prev.code,
                  fromSelectedId: prev.selectedId,
                }));
              }}
            >
              <span>オープン</span>
              <img src={iconTabOpen} width="24" alt="" />
            </li>

            <li
              className={`${css["headerTab_menuIcon"]} ${
                modeFirst.code === "MATCH" ? css.active : ""
              }`}
              onClick={() => {
                setModeFirst((prev: ModeFirst) => ({ ...prev, code: "MATCH" }));
                setModeSecond((prev: ModeSecond) => ({
                  ...prev,
                  code: "LIST",
                  fromCode: prev.code,
                  fromSelectedId: prev.selectedId,
                }));
              }}
            >
              <span>成立</span>
              <img src={iconTabMatch} width="24" alt="" />
            </li>

            <li
              className={`${css["headerTab_menuIcon"]} ${
                modeFirst.code === "CLOSE" ? css.active : ""
              }`}
              onClick={() => {
                setModeFirst((prev: ModeFirst) => ({ ...prev, code: "CLOSE" }));
                setModeSecond((prev: ModeSecond) => ({
                  ...prev,
                  code: "LIST",
                  fromCode: prev.code,
                  fromSelectedId: prev.selectedId,
                }));
              }}
            >
              <span>クローズ</span>
              <img src={iconTabClose} width="24" alt="" />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AssistTitle;
