import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";

import SupportItems from "./comp_SupportItems";

import {
  initialSupportList,
  searchSupportList,
} from "../../components/apiAccess/thread/searchSupporterDataAccess";
import type {
  ConditionSupportList,
  Support,
  SupportList,
} from "../../components/apiAccess/thread/searchSupporterDataAccess";

import backArrow from "../../../../img/backArrow.svg";
import css from "./OthersAssistList.module.css";
import { UserInfoContext } from "../../components/providers/userInfoProvider";
// 後に復活する可能性あり
// import SettingCondition from "./comp_SettingCondition";

// みんなの助け隊一覧
const OthersAssistList = () => {
  // 助け隊一覧データ
  const [supportList, setSupportList] =
    useState<SupportList>(initialSupportList);
  // 戻るナビゲート
  const navigate = useNavigate();
  // 現在のページ
  const refPage = useRef<number>(0);
  // 読み込むページの有無
  const refHasMore = useRef<boolean>(true);
  // データ取得中フラグ
  const refIsLoading = useRef<boolean>(false);
  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);

  // 絞り込み要素（ページ指定を含む）
  const [condition, setCondition] = useState<ConditionSupportList>({
    user_id: userInfo?.id!,
    owner_class: 1,
    // support_date: "",
    // time_from: "",
    // category: [],
    // target: [],
    // appoint: [],
    // alliliation: 0,
    page: 0,
    recruitment_flag: 0,
  });

  // 絞り込み
  const refFilter = useRef<HTMLInputElement>(null!);
  // 無限スクロールコンテナの高さ
  const [containerHeight, setContainerHeight] = useState({});

  // 初回レンダリング実行
  useEffect(() => {
    // console.log("「みんなの助け隊一覧」画面 初回レンダリング実行");
    // console.log("window.innerHeight = " + window.innerHeight);

    // 無限スクロールコンテナの高さを設定
    setContainerHeight({
      height: window.innerHeight + "px",
    });
  }, []);

  // 戻るアイコンがタップされたとき
  const handlerOnClickBackArrow = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "mainMenu");
  };

  // 取得条件が変わったとき
  useEffect(() => {
    if (condition.page === 0) {
      return;
    }

    // console.log("取得条件が変わったとき");
    refPage.current = 1;
    fetch(condition);
  }, [condition]);

  // 助け隊取得
  const fetch = (cnd: ConditionSupportList) => {
    refIsLoading.current = true;

    searchSupportList(cnd)
      .then((response: any) => {
        if (response.data.page === "1") {
          setSupportList(response.data);
        } else {
          const tempList: Support[] = supportList?.result.concat(
            response.data.result
          );
          setSupportList((prev) => ({
            ...prev,
            result: tempList,
            page: response.data.page,
          }));
        }

        if (response.data.page === response.data.page_total) {
          refHasMore.current = false;
        }
        refIsLoading.current = false;
      })
      .catch((e) => {
        console.dir(e);
        process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
      });
  };

  // 1ページ単位でデータを取得する
  const loadSupport = async () => {
    // console.log("called: loadSupport funtcion.");
    if (refIsLoading.current) return;

    if (userInfo === undefined || userInfo === null) {
      return;
    }

    refPage.current++;
    let recruitmentFlag = 0;
    if (refFilter.current.checked) {
      recruitmentFlag = 1;
    }
    const cnd: ConditionSupportList = {
      ...condition,
      page: refPage.current,
      recruitment_flag: recruitmentFlag,
    };
    fetch(cnd);
  };

  //　「募集中の案件のみ表示する」チェックがタップされたとき
  const onFilterChecked = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();

    refPage.current = 1;
    let recruitmentFlag = 0;
    if (refFilter.current.checked) {
      recruitmentFlag = 1;
    }

    setCondition((prev) => ({
      ...prev,
      recruitment_flag: recruitmentFlag,
      page: 1,
    }));
  };

  return (
    <div className={css.boardPanel_outer_container} style={containerHeight}>
      <div className={`${css.pageTitle} ${css["pageTitle_color-everyones"]}`}>
        <div className={css.backArrow} onClick={handlerOnClickBackArrow}>
          <img src={backArrow} width="23" alt="" />
        </div>
        みんなの助け隊一覧
      </div>

      {/* 絞り込み */}
      {/* <SettingCondition setCondition={setCondition} isLoading={isLoading} /> */}
      <div className={css.filter}>
        <input
          type="checkbox"
          id="assist_cond"
          onClick={onFilterChecked}
          ref={refFilter}
        />
        <label htmlFor="assist_cond">
          <span>&emsp;&emsp;&ensp;</span>募集中の案件のみ表示する
        </label>
        <hr />
      </div>

      {/* 援助申し出リスト */}
      <InfiniteScroll
        loadMore={loadSupport}
        hasMore={refHasMore.current}
        useWindow={false}
      >
        <SupportItems list={supportList.result} />
      </InfiniteScroll>
    </div>
  );
};

export default OthersAssistList;
