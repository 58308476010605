import { Dispatch, Fragment, SetStateAction, memo, useEffect } from "react";
import { SupporterSetData } from "../../components/apiAccess/thread/useSetSupporter";
import css from "./comp_Towns.module.css";

interface TownsProps {
  townNames: string[];
  areaChoice: string[];
  setAreaChoice: Dispatch<SetStateAction<string[]>>;
}

const Towns = memo((props: TownsProps) => {
  const { townNames, areaChoice, setAreaChoice } = props;

  // useEffect(() => {
  //   if (areaChoice.length > 0) {
  //     console.dir(areaChoice);
  //   }
  // }, [areaChoice]);

  const extractTownName = (str: string): string => {
    const pattern = new RegExp("[（）ぁ-ん]", "g");
    const selectedValue = str.replace(pattern, "");
    return selectedValue;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    // console.log("tapped.");

    const selectedValue = extractTownName(e.target.value);

    if (areaChoice.includes(selectedValue)) {
      // 町名が選択済みの場合
      const filtered_town_name = areaChoice.filter(
        (checkedValue: any) => checkedValue !== selectedValue
      );
      // console.dir(filtered_town_name);
      setAreaChoice((prev: string[]) => filtered_town_name);
    } else {
      // 町名が未選択の場合
      const selected_town_name = [...areaChoice, selectedValue];
      setAreaChoice((prev: string[]) => selected_town_name);
    }
  };

  const judgeChecked = (town: string) => {
    const selectedValue = extractTownName(town);

    if (areaChoice.includes(selectedValue)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={css["checkboxList-wrap"]}>
      {townNames.map((town: string) => (
        <>
          <Fragment key={town}>
            <input
              type="checkbox"
              id={town}
              value={town}
              onChange={handleChange}
              checked={judgeChecked(town)}
            />
            <label htmlFor={town} className={css.checkboxList}>
              <span>{town}</span>
            </label>
          </Fragment>
        </>
      ))}
    </div>
  );
});

export default Towns;
