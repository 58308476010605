import css from "./Chat.module.css";
import { Fragment, memo, useEffect } from "react";
import { ChatItem } from "../../components/apiAccess/chat/getMessageAllDataAccess";
import dayOfWeekStrJP from "../../components/dateUtil/Method_dayOfWeekStrJP";

const ChatMassage = memo((props: any) => {
  const { chatList, myId, yourId, scrollTarget } = props;

  // useEffect(() => {
  //   console.log(`初回レンダリング実行 チャットメッセージ myId=${myId} yourId=${yourId}`);
  // }, []);

  const dateFormatted = (datestr: string): string => {
    const dt: string[] = datestr.split("T");
    const dt2: string[] = dt[0].split("-");

    const y = dt2[0];
    const m = String(Number(dt2[1]));
    const d = String(Number(dt2[2]));

    const indicateDate: string =
      y + "年" + m + "月" + d + "日(" + dayOfWeekStrJP(dt[0]) + ")";
    // console.log("チャット送信日付:" + indicateDate);
    return indicateDate;
  };

  // console.log("scrollTarget = " + scrollTarget);
  let prevSendDate: string;
  return (
    <>
      {chatList &&
        chatList.length > 0 &&
        chatList[0].message !== "" &&
        chatList.reverse().map((chatItem: ChatItem, index: number) => {
          const dateTime: string[] = chatItem.date.split("T");
          let isVisibleDate = false;
          // console.log("prevSendDate=" + prevSendDate);
          // console.log("dateTime[0]=" + dateTime[0]);
          if (
            (prevSendDate !== undefined && prevSendDate !== dateTime[0]) ||
            index === 0
          ) {
            isVisibleDate = true;
          }
          prevSendDate = dateTime[0];
          return (
            <>
              <Fragment key={chatItem.message_no}>
                {isVisibleDate && (
                  <div className={css.chatDate}>
                    {dateFormatted(dateTime[0])}
                  </div>
                )}
                {chatList.length - scrollTarget === index && (
                  <div id="scrollTarget"></div>
                )}
                {chatItem.user_id === myId && (
                  <div
                    className={`${css["chatTextbox-wrap"]} ${css["chatText-your"]}`}
                    data-index={index}
                  >
                    <div className={css.chatTextbox}>{chatItem.message}</div>
                    <div className={css.chatSendInfo}>
                      <div>
                        <span className={css["chatSendInfo-time"]}>
                          {dateTime[1].substring(0, 5)}
                        </span>
                      </div>
                      {/* <div className={css["chatSendInfo-read"]}>既読</div> */}
                    </div>
                  </div>
                )}
                {chatItem.user_id !== myId && (
                  <div
                    className={`${css["chatTextbox-wrap"]} ${css["chatText-self"]}`}
                    data-index={index}
                  >
                    <div className={css.chatTextbox}>{chatItem.message}</div>
                    <div className={css.chatSendInfo}>
                      <div>
                        <span className={css["chatSendInfo-time"]}>
                          {dateTime[1].substring(0, 5)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            </>
          );
        })}
    </>
  );
});

export default ChatMassage;
