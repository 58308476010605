import axios from "axios";
import GetApiUrl from "../getApiUrl";

const assistSvr = axios.create({
    baseURL: GetApiUrl(),
});

export const getEventAll = async (props: any) => {
    const { user_id, page } = props;
    // console.log("called: getEventAll page= " + page);
    // console.dir(props);

    return await assistSvr.get(`/event/getEventAll?user_id=${user_id}&page=${page}`).then((response) => {
        // console.dir(response.data);
        return response;
    });
}

export type Condition = {
    user_id: string;
    system_day: string;
    page: number;
}

export type MebukuEvent = {
    event_id: string;
    charges: string;
    event_name: string;
    event_join_flg: string;
    event_icon: string;
    event_label_text: string;
    event_theme_color: string;
    event_desc_text: string;
    event_desc_date: string;
    event_desc_charge: string;
    event_desc_place: string;
    event_desc_url: string;
    event_begin_date: string;
    event_end_date: string;
    event_joining_begin: string;
    event_joining_end: string;
};

export type Res = {
    result_code: string;
    message: string;
    result: MebukuEvent[];
    count: number;
    page: number;
    page_total: number;
};

export const initialRes = {
    result_code: "",
    message: "",
    result: [],
    count: 0,
    page: 0,
    page_total: 0
};