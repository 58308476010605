import axios from "axios";
import GetApiUrl from "../getApiUrl";

export const postExecuteJoin = async (props: any) => {

    const assistSvr = axios.create({
        baseURL: GetApiUrl(),
        responseType: "json",
        headers: {
            "Content-Type": "application/json",
        },
    });

    // console.dir(props);
    const {
        account_id,
        event_id,
        qr_read_code
    } = props;


    return assistSvr
        .post(`/passport/executeJoin`, {
            account_id,
            event_id,
            qr_read_code
        })
        .then((response) => {
            // console.dir(response.data);
            return response;
        })
};

export type ConditionExecuteJoin = {
    account_id: string;
    event_id: string;
    qr_read_code: string;
};

export type ResultData = {
    result_code: string;    // 
    message: string;        // エラーメッセージ
};
