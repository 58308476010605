import React, { useState, useEffect, useContext, memo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ChangeCameraAndQrcode from "./comp_ChangeCameraAndQrcode";
import { useGetJoinNum } from "../../components/apiAccess/passport/useGetJoinNum";
import checkParameter from "../../components/providers/CheckUserInfo";
import { UserInfoContext } from "../../components/providers/userInfoProvider";

import css from "./Passport.module.css";
import iconCamera from "../../../../img/icon_camera.svg";
import iconQrcode from "../../../../img/icon_qrcode.svg";
import ReloadPic from "../../../../img/reload_btn.png";
import {
  EventData,
  SpecifiedEventInfo,
  useGetEventAll,
} from "../../components/apiAccess/event/useGetEventAll";
import {
  ProfiledData,
  useGetProfile,
} from "../../components/apiAccess/profile/useGetProfile";
import {
  AccountInfo,
  useSetAccount,
} from "../../components/apiAccess/account/useSetAccount";
import type { ConditionActivate } from "../../components/apiAccess/event/useExecuteActivate";
import { useExecuteActivate } from "../../components/apiAccess/event/useExecuteActivate";
import type { UserInfoType } from "../../components/providers/types";

type JoinPointType = {
  assistance: number;
  event: number;
};

const Passport = memo(() => {
  // クエリーパラメータを取得する
  const { search } = useLocation();
  // イベント一覧を取得するカスタムフック
  const {
    resultGetEventAll,
    getEventAll,
    isLoadingEventAll,
    isErrorGetEventAll,
  } = useGetEventAll();

  // Join件数を取得するカスタムフック
  const { resultGetJoinNum, getJoinNum, isLoadingJoinNum, isErrorGetJoinNum } =
    useGetJoinNum();

  // イベントに参加するカスタムフック
  const {
    resultActivate,
    executeActivate,
    isSendingActivate,
    isErrorActivate,
  } = useExecuteActivate();

  // 遷移設定
  const navigate = useNavigate();
  // ログインユーザ情報
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  // プロフィール取得
  const { resultGetProfile, getProfile, isLoadingProfile, isErrorGetProrile } =
    useGetProfile();
  // プロフィール設定
  const { resultSetAccount, setAccount, isSendingAccount, isErrorSetAccount } =
    useSetAccount();
  // 新規ユーザか否か
  const [isFirstTime, setIsFirstTime] = useState<boolean>(false);

  // 選択イベントID
  const [eventId, setEventId] = useState<string>("");
  // カメラが選択中か否か
  // const [isCameraMode, setIsCameraMode] = useState<boolean>(true);
  // QRコード読み取りモードが選択中か否か
  const [isQrIndicatiorMode, setIsQrIndicatiorMode] = useState<boolean>(false);
  // カメラ有効／無効（起動／停止）
  const [isCameraActive, setIsCameraActive] = useState<boolean>(true);
  // Joinの表示ポイント
  const [joinPoint, setJoinPoint] = useState<JoinPointType>({
    assistance: 0,
    event: 0,
  });
  // Join成功フラグ
  const [isSuccessJoin, setIsSuccessJoin] = useState<boolean>(false);
  const refSelect = useRef<HTMLSelectElement>(null!);
  const location = useLocation();
  const [defaultJoinString, setDefaultJoinString] = useState<string>();

  type OptionData = {
    name: string;
    value: string;
  };
  const [optionData, setOptionData] = useState<OptionData[]>([]);

  // スマホの縦横状態
  const [isPortrait, setIsPortrait] = useState<boolean>(true);

  // QRコード更新フラグ
  const [change, setChange] = useState<number>(0);

  // 初回実行
  useEffect(() => {
    const queryParam = new URLSearchParams(search);
    const user = checkParameter(queryParam);

    if (
      user === null ||
      user === undefined ||
      user.id === null ||
      user.id === ""
    ) {
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert("パラメータが不足しています");
      console.log("ユーザー情報不足のため処理中断");
      return;
    } else {
      setUserInfo(user);
    }
    // プロフィール取得
    getProfile(user.id);

    window.localStorage.setItem("camera_control", "1");
    setIsCameraActive(true);

    // カメラON/OFF指示検出
    window.addEventListener("storage", function (e: any) {
      // console.log("== storage の発火を検出 ==", e);

      if (e.key === "camera_control") {
        // console.log("e.newValue=" + e.newValue);
        if (e.newValue === "1") {
          setIsCameraActive(true);
          // console.log("カメラON");
        } else {
          setIsCameraActive(false);
          // console.log("カメラOFF");
        }
      }
    });

    // 初回の角度検出
    setDeviceAngle();

    // 向き検出イベント
    window.addEventListener("orientationchange", setDeviceAngle);
    return () => {
      window.removeEventListener("orientationchange", setDeviceAngle);

      // TODO
      window.removeEventListener("storage", function (e: any) {
        if (e.key === "camera_control") {
          console.log("camera_controlの値が変更されました:", e.newValue);
        }
      });
    };
  }, []);

  // スマホの縦横検出
  const setDeviceAngle = () => {
    // console.log("orientationchange 検出");

    const userAgent = window.navigator.userAgent;
    // console.log(userAgent);

    // 角度を取得
    let angle;
    if (userAgent.match(/Android/)) {
      // console.log("デバイス: Android");
      angle = window.screen.orientation.angle;
    } else if (userAgent.match(/iPhone/)) {
      // console.log("デバイス: iPhone");
      // 2023年6月現在、iOSは 非推奨の window.orientation で角度を取得する必要あり
      angle = window.orientation;
    } else {
      // console.log("デバイス: Windows or Mac");
      angle = window.screen.orientation.angle;
    }

    // console.log("角度：" + angle);
    if (angle === 0) {
      // console.log("向き：縦");
      setIsPortrait(true);
    } else {
      // console.log("向き：横");
      setIsPortrait(false);
    }
  };

  // プロフィール取得結果が返ってきたとき
  useEffect(() => {
    if (resultGetProfile === undefined) return;
    if (resultGetProfile.result_code === "0") {
      // 正常のとき
      const user: UserInfoType = {
        id_kind: resultGetProfile.result.id_type,
        id: resultGetProfile.result.user_id,
        nickname: resultGetProfile.result.nickname,
        mail: resultGetProfile.result.mail_address,
        version: resultGetProfile.result.version,
        client_id: resultGetProfile.result.client_id,
      };
      setUserInfo(user);

      // console.log("ユーザー登録あり");
      // ユーザ登録済み
      // console.log("イベント一覧とジョイン数を取得");
      // イベント一覧を取得
      const eventInfo: SpecifiedEventInfo = {
        user_id: user.id,
        page: "1",
      };
      getEventAll(eventInfo);
      // ジョイン数を取得
      getJoinNum(user.id);

      if (!checkUserInfo(resultGetProfile.result)) {
        // 不一致情報がある場合は、更新する
        // console.log("めぶくID以外のユーザー情報が不一致、ユーザー情報更新");
        registUpdateUser();
      }
    } else if (
      resultGetProfile.result_code === "1" ||
      resultGetProfile.result_code === "9"
    ) {
      // 異常のとき
      console.log(resultGetProfile.message);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultGetProfile.message);

      // ユーザ未登録。新規登録する。
      setIsFirstTime(true);
      registUpdateUser();
      registCoopbbs();
    }
  }, [resultGetProfile]);

  // ユーザー情報の登録／更新
  const registUpdateUser = () => {
    const account: AccountInfo = {
      user_id: userInfo?.id!,
      id_type: userInfo?.id_kind!,
      nickname: userInfo?.nickname!,
      mail_address: userInfo?.mail!,
      version: userInfo?.version!,
      client_id: userInfo?.client_id!,
    };
    setAccount(account);
  };

  // 助け合いに登録
  const registCoopbbs = () => {
    const conditionActivate: ConditionActivate = {
      account_id: userInfo?.id!,
      activation_cd: "",
      event_id: "2", // 助け合いのイベントID
    };
    executeActivate(conditionActivate);
  };

  // 助け合いに登録の結果を受信したとき
  useEffect(() => {
    if (resultActivate === null || resultActivate === undefined) return;

    if (resultActivate.result_code === "0") {
      // 助け合いに登録できたら、イベント一覧を取得する
      const eventInfo: SpecifiedEventInfo = {
        user_id: userInfo?.id!,
        page: "1",
      };
      getEventAll(eventInfo);
      // ジョイン数を取得
      getJoinNum(userInfo?.id!);
    }
  }, [resultActivate]);

  // ユーザー情報の登録／更新結果を受信したとき
  useEffect(() => {
    if (resultSetAccount === undefined || resultSetAccount === null) return;

    if (resultSetAccount.result_code === "0") {
      // console.log("ユーザー情報の登録／更新 完了");
      if (isFirstTime) {
        // プロフィール取得
        getProfile(userInfo?.id!);
      } else {
        // console.log("プロフィール登録済み、イベント一覧とジョイン数を取得");
        // イベント一覧を取得
        const eventInfo: SpecifiedEventInfo = {
          user_id: userInfo?.id!,
          page: "1",
        };
        getEventAll(eventInfo);
        // ジョイン数を取得
        getJoinNum(userInfo?.id!);
      }
    } else {
      // console.log("ユーザー情報の登録／更新 失敗");
      // console.log(resultSetAccount.message);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultSetAccount.message);
    }
  }, [resultSetAccount]);

  const checkUserInfo = (prof: ProfiledData): boolean => {
    if (prof.id_type !== userInfo?.id_kind) return false;
    // if (prof.user_id !== userInfo?.id) return false;
    if (prof.nickname !== userInfo?.nickname) return false;
    if (prof.mail_address !== userInfo?.mail) return false;
    if (prof.version !== userInfo?.version) return false;
    if (prof.client_id !== userInfo?.client_id) return false;

    return true;
  };

  // マイ・ライフ・ログへの遷移設定
  const loginMyLifeLog = () => {
    if (userInfo !== null) {
      const query =
        `?id_kind=${userInfo.id_kind}` +
        `&id=${userInfo.id}` +
        `&nickname=${userInfo.nickname}` +
        `&mail=${userInfo.mail}` +
        `&version=${userInfo.version}` +
        `&client_id=${userInfo.client_id}`;

      navigate(process.env.REACT_APP_PATH_PREFIX + "myLifeLog" + query);
    }
  };

  // Joinフラグが変化したとき
  useEffect(() => {
    // console.log("isSuccessdJoin フラグに変化あり");
    if (isSuccessJoin) {
      // ジョイン数を取得
      getJoinNum(userInfo?.id!);
      setIsSuccessJoin(false);
    }
  }, [isSuccessJoin]);

  // Join件数を取得したとき
  useEffect(() => {
    if (resultGetJoinNum === undefined || resultGetJoinNum === null) return;

    if (resultGetJoinNum.result.length > 0) {
      // console.dir(resultGetJoinNum);

      let assistance = 0;
      let event = 0;
      resultGetJoinNum.result.forEach((join) => {
        if (
          join.event_name.includes("助け合い") ||
          join.event_name.includes("共助")
        ) {
          assistance += Number(join.count);
        } else {
          event += Number(join.count);
        }
      });
      setJoinPoint({ assistance, event });

      if (location && location.state && location.state.join) {
        setDefaultJoinString(location.state.join);
      }

      const options = refSelect.current.children;
      // console.dir(options);

      let no = 0;
      for (let i = 0; i < options.length; i++) {
        if (
          options[i].innerHTML.includes("共助") ||
          options[i].innerHTML.includes("助け合い")
        ) {
          no = i;
          break;
        }
      }
    }
  }, [resultGetJoinNum]);

  // チャット画面から遷移した場合、Join選択を「助け合い」にする
  // useEffect(() => {
  //   if (defaultJoinString === undefined || defaultJoinString === null) return;
  //   console.log("defaultJoinString:" + defaultJoinString);

  //   // refSelect.current.options[1].selected = true;
  //   // const value = refSelect.current.options[1].value;
  //   const options = refSelect.current.children;
  //   console.dir(options);

  //   let no = 0;
  //   for (let i = 0; i < options.length; i++) {
  //     if (
  //       options[i].innerHTML.includes("共助") ||
  //       options[i].innerHTML.includes("助け合い")
  //     ) {
  //       no = i;
  //       break;
  //     }
  //   }

  //   refSelect.current.options[no].selected = true;
  //   const value = refSelect.current.options[no].value;
  //   setJoin(value);
  // }, [defaultJoinString]);

  // イベント選択が変わったとき
  const handlerOnChangeEvent = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.stopPropagation();
    // console.log("イベントが選択されました");
    const value = e.currentTarget.value;
    setJoin(value);
    getQrCode();
  };

  const setJoin = (value: string) => {
    if (value === "") {
      return;
    }
    setEventId(value);
  };

  // カメラ／QRコードアイコンがタップされたとき
  const handlerOnClickQrButton = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (isQrIndicatiorMode) {
      setIsQrIndicatiorMode(false);
      window.localStorage.setItem("camera_control", "1");
      setIsCameraActive(true);
      // console.log("カメラモードに移行");
    } else {
      setIsQrIndicatiorMode(true);
      window.localStorage.setItem("camera_control", "0");
      setIsCameraActive(false);
      // console.log("QRコード表示モードに移行");
    }
  };

  const SelectOptions = (list: any): any => {
    if (list && list.length > 0) {
      list.map((data: EventData) => {
        if (data.event_join_flg === "1") {
          return (
            <option value={data.event_id} key={data.event_id}>
              {data.event_name}
            </option>
          );
        } else {
          return null;
        }
      });
    }
  };

  // リロードボタンがタップされたとき
  const reload = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    getQrCode();
  };

  // QRコード再取得
  const getQrCode = ()=> {
    if (isQrIndicatiorMode) {
      setChange((prevChange) => prevChange + 1);
    }
    getJoinNum(userInfo?.id!);
  };

  // イベント一覧を取得したとき
  useEffect(() => {
    if (resultGetEventAll === undefined || resultGetEventAll === null) return;

    // 共助に参加しているかをチェック
    let isCoopbbsJoined = false;
    for (let i = 0; i < resultGetEventAll.result.length; i++) {
      if (
        resultGetEventAll.result[i].event_name === "前橋共助" &&
        resultGetEventAll.result[i].event_join_flg === "1"
      ) {
        isCoopbbsJoined = true;
        break;
      }
    }

    if (isCoopbbsJoined) {
      // console.log("共助に参加済み、イベント選択肢をするプルダウンにセットする");
      if (resultGetEventAll.result.length > 0) {
        setOptionData([]);

        resultGetEventAll.result.forEach((element) => {
          if (element.event_join_flg === "1") {
            if (element.event_name === "前橋共助") {
              const option: OptionData = {
                name: "助け合い Join",
                value: element.event_id,
              };
              setOptionData((prev) => [option, ...prev]);
              setEventId(element.event_id);
            } else {
              const option: OptionData = {
                name: `イベント Join（${element.event_name}）`,
                value: element.event_id,
              };
              setOptionData((prev) => [...prev, option]);
            }
          }
        });
      }
    } else {
      // console.log("共助に未参加のため、新たに参加する");
      registCoopbbs();
    }
  }, [resultGetEventAll]);

  return (
    <>
      <article className={css.content_all}>
        <div className={css.header}>
          <div className={css.join_bar_block}>
            <div className={css.title}>
              <span>Joinする種類を選択</span>
            </div>
            <div className={css.selectWrap}>
              <select
                onChange={handlerOnChangeEvent}
                ref={refSelect}
                disabled={isLoadingJoinNum}
              >
                {optionData &&
                  optionData.length > 0 &&
                  optionData.map((data: OptionData) => {
                    return (
                      <option value={data.value} key={data.value}>
                        {data.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
        <ChangeCameraAndQrcode
          isQrIndicatiorMode={isQrIndicatiorMode}
          eventId={eventId}
          isPortrait={isPortrait}
          setIsSuccessJoin={setIsSuccessJoin}
          isCameraActive={isCameraActive}
          qrChange={change}
        />
        <div className={css.full_width}>
          <div className={css.qr_black_bar}>
            <div
              className={css.camera_icon_circle}
              onClick={handlerOnClickQrButton}
            >
              {isQrIndicatiorMode && <img src={iconCamera} alt="" />}
              {!isQrIndicatiorMode && (
                <img src={iconQrcode} width="25px" height="25px" alt="" />
              )}
            </div>
          </div>
        </div>
        <div className={`${css.content_inner} ${css.article_margin}`}>
          <div className={css.join_bar_block}>
            <div className={css.total_join_bar}>
              <div className={`${css.join_name} ${css.bg_red}`}>
                助け合い Join
              </div>
              <div className={`${css.join_total} ${css.border_red}`}>
                <div>{joinPoint.assistance}</div>
              </div>
            </div>
            <div className={css.total_join_bar}>
              <div className={`${css.join_name} ${css.bg_green}`}>
                イベント Join
              </div>
              <div className={`${css.join_total} ${css.border_green}`}>
                <div>{joinPoint.event}</div>
              </div>
            </div>
          </div>
          <div className={css.reload_btn}>
            <img
              src={ReloadPic}
              alt="picture"
              width="50"
              height="50"
              onClick={reload}
            />
          </div>
          {/* <div className={css.btn_wrapper}>
            <div className={css.btn_green} onClick={loginMyLifeLog}>
              my ライフログ
              <FontAwesomeIcon
                icon={faAngleRight}
                className={css.faAngleRight}
              />
            </div>
          </div> */}
        </div>
        {/* <div>id_kind = {userInfo?.id_kind}</div>
        <div>id = {userInfo?.id}</div>
        <div>mail = {userInfo?.mail}</div>
        <div>nickname = {userInfo?.nickname}</div>
        <div>version = {userInfo?.version}</div>
        <div>client_id = {userInfo?.client_id}</div> */}
      </article>
    </>
  );
});

export default Passport;
