import jsQR from "jsqr";
import { useEffect } from "react";

const QrReader = (props: any) => {
  const { cameraWidth, cameraHeight, scanedCode, setScanedCode } = props;

  let video: HTMLVideoElement;
  let canvasElement: HTMLCanvasElement;
  let canvas: any;
  let videoStream: any;

  function drawLine(begin: any, end: any, color: any) {
    canvas!.beginPath();
    canvas!.moveTo(begin.x, begin.y);
    canvas!.lineTo(end.x, end.y);
    canvas!.lineWidth = 4;
    canvas!.strokeStyle = color;
    canvas!.stroke();
  }

  useEffect(() => {
    video = document.getElementById("video") as HTMLVideoElement;
    canvasElement = document.getElementById("canvas") as HTMLCanvasElement;
    canvas = canvasElement?.getContext("2d");

    const supports = navigator.mediaDevices.getSupportedConstraints();
    if (supports.width && supports.height && supports.facingMode) {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: "environment",
            width: cameraWidth,
            height: cameraHeight,
          },
          audio: false,
        })
        .then(function (stream) {
          videoStream = stream;
          video.srcObject = stream;
          video.play();
          requestAnimationFrame(tick);
        });
    } else {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: false,
        })
        .catch((err) => {
          console.log(JSON.stringify(err));
        });
    }
  }, [cameraWidth, cameraHeight]);

  let enableRefresh: boolean = true;
  function tick() {
    if (
      video.readyState === video.HAVE_ENOUGH_DATA &&
      scanedCode === "" &&
      enableRefresh
    ) {
      canvasElement.hidden = false;

      // カメラ制御フラグで継続/停止を判定する
      // if (localStorage.getItem("camera_control") !== "1") {
      //   videoStream.getVideoTracks().forEach((track: any) => {
      //     track.stop();
      //   })
      //   return;
      // }

      if (video.videoHeight < video.videoWidth) {
        canvasElement.width = video.videoWidth;
        canvasElement.height = video.videoHeight;
      } else {
        canvasElement.width = video.videoHeight;
        canvasElement.height = video.videoWidth;
      }

      if (window.localStorage.getItem("camera_control") !== "1") {
        video = document.createElement("video");
        // video ;
      }
      canvas!.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);

      // カメラ制御フラグで継続/停止を判定する
      if (localStorage.getItem("camera_control") !== "1") {
        videoStream.getVideoTracks().forEach((track: any) => {
          track.stop();
        })
        return;
      }

      var imageData = canvas!.getImageData(
        0,
        0,
        canvasElement.width,
        canvasElement.height
      );
      var code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: "dontInvert",
      });
      if (code) {
        drawLine(
          code.location.topLeftCorner,
          code.location.topRightCorner,
          "#FF3B58"
        );
        drawLine(
          code.location.topRightCorner,
          code.location.bottomRightCorner,
          "#FF3B58"
        );
        drawLine(
          code.location.bottomRightCorner,
          code.location.bottomLeftCorner,
          "#FF3B58"
        );
        drawLine(
          code.location.bottomLeftCorner,
          code.location.topLeftCorner,
          "#FF3B58"
        );

        if (code.data !== "") {
          // console.log("set scan code.");
          setScanedCode(code.data);
          enableRefresh = false;
          setTimeout(() => {
            enableRefresh = true;
          }, 2000);
        }
      }
    } else {
      console.log("カメラ画像の更新を中断");
    }
    requestAnimationFrame(tick);
  }

  return (
    <>
      <video id="video" autoPlay muted playsInline hidden></video>
      <canvas id="canvas" hidden></canvas>
    </>
  );
};

export default QrReader;
