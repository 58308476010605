import React, { useContext, useEffect } from "react";
import {
  ConditionActivate,
  useExecuteActivate,
} from "../../components/apiAccess/event/useExecuteActivate";
import { UserInfoContext } from "../../components/providers/userInfoProvider";

import type { MebukuEvent } from "../../components/apiAccess/event/getEventAllDataAccess";

import css from "./Event.module.css";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";

const EventView = (props: any) => {
  const { eventList, setJoinEventId } = props;
  const {
    resultActivate,
    executeActivate,
    isSendingActivate,
    isErrorActivate,
  } = useExecuteActivate();

  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);

  useEffect(() => {
    if (resultActivate?.result_code === "0") {
      setJoinEventId(resultActivate.event_id);
    }
  }, [resultActivate]);

  // イベントパネルのヘッダがタップされたとき
  const handlerOnClickTitle = (e: any) => {
    const joinFlg = e.currentTarget.dataset.joinflg;
    if (joinFlg !== "0") {
      return;
    }

    if (userInfo === undefined || userInfo === null) {
      return;
    }

    const beginJoining = e.currentTarget.dataset.joiningbegin;
    const endJoining = e.currentTarget.dataset.joiningend;
    if (!isParticipationPeriodActive(beginJoining, endJoining)) {
      // console.log("現在は参加期間外");
      return;
    }

    const userId = userInfo.id;
    const eventId = e.currentTarget.dataset.eventid;
    const conditionActivate: ConditionActivate = {
      account_id: userId,
      activation_cd: "",
      event_id: eventId,
    };

    executeActivate(conditionActivate);
  };

  // イベントへの参加可否判定
  const isParticipationPeriodActive = (begin: string, end: string) => {
    let now: Date = new Date();
    // console.log('現在=' + now);

    // 正規表現で「yyyy-mmdd」を「yyyy-mm-dd」に変換し、空白を「T」に置換して ISO 8601 形式にする
    const formattedBegin = begin.replace(/^(\d{4})-(\d{2})(\d{2})/, "$1-$2-$3").replace(" ", "T");
    const formattedEnd = end.replace(/^(\d{4})-(\d{2})(\d{2})/, "$1-$2-$3").replace(" ", "T");

    // ISO 8601 形式になったので Date コンストラクタで変換
    const beginObj = new Date(formattedBegin);
    const endObj = new Date(formattedEnd);
    // console.log('開始=' + beginObj);
    // console.log('終了=' + endObj);

    if (beginObj.getTime() <= now.getTime()
      && endObj.getTime() > now.getTime()) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      {eventList &&
        eventList.length > 0 &&
        eventList.event_name !== "" &&
        eventList.map((data: MebukuEvent) => {
          if (data.event_id === "2") {
            return null;
          } else {
            return (
              <div className={css.event_block} key={data.event_id}>
                <Card className={`${css.text_center}`}>
                  <Card.Header
                    className={
                      data.event_join_flg === "1"
                        ? css.event_part_state_green
                        : css.event_part_state_blue
                    }
                    onClick={handlerOnClickTitle}
                    data-joinflg={data.event_join_flg}
                    data-eventid={data.event_id}
                    data-joiningbegin={data.event_joining_begin}
                    data-joiningend={data.event_joining_end}
                  >
                    <p></p>
                    <p>
                      {data.event_join_flg === "1"
                        ? "このイベントに参加中"
                        : (isParticipationPeriodActive(data.event_joining_begin, data.event_joining_end) ? "このイベントに参加する" : "現在参加できません")}
                    </p>
                    {data.event_join_flg === "1"
                      ? <p></p>
                      : (isParticipationPeriodActive(data.event_joining_begin, data.event_joining_end) ? <FontAwesomeIcon icon={faAngleRight} size="xl" /> : <p></p>)}
                  </Card.Header>
                  <Card.Body className={css.card_body}>
                    <Card.Title className={css.event_name}>
                      <div className={css.event_title}>{data.event_name}</div>
                      <div className={css.event_logo}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/" +
                            data.event_icon
                          }
                          alt="picture"
                          width="145"
                          height="80"
                        />
                      </div>
                    </Card.Title>
                    <Card.Text className={css.event_summary}>
                      {data.event_desc_text}
                    </Card.Text>
                    <div className={css.event_detail}>
                      <div className={css.item_name}>開催日時</div>
                      <div className={css.item_name_text}>
                        {data.event_desc_date}
                      </div>
                      <hr className={css.event_hr} />
                      <div className={css.item_name}>参加料金</div>
                      <div className={css.item_name_text}>
                        {data.event_desc_charge}
                      </div>
                      <hr className={css.event_hr} />
                      <div className={css.item_name}>開催場所</div>
                      <div className={css.item_name_text}>
                        {data.event_desc_place}
                      </div>
                      <hr className={css.event_hr} />
                      <div className={css.item_name}>公式サイト</div>
                      <div className={css.item_name_text}>
                        <a target="_blank" href={data.event_desc_url}>
                          {data.event_desc_url}
                        </a>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            );
          }
        })}
    </>
  );
};

export default EventView;
