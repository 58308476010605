import React, { useState, useEffect, useRef, useContext } from "react";
import css from "./IntroViewEdit.module.css";
import { useNavigate } from "react-router-dom";
import ErrorModalOverWordCount from "../../components/ErrorModal/ErrorModalOverWordCountTwoHundred";
import ErrorModalSensitiveWord from "../../components/ErrorModal/ErrorModalSensitiveWord";
import { UserInfoContext } from "../../components/providers/userInfoProvider";
import { useGetProfile } from "../../components/apiAccess/profile/useGetProfile";
import {
  UserProfile,
  useSetProfile,
} from "../../components/apiAccess/profile/useSetProfile";
import backArrow from "../../../../img/backArrow.svg";

function IntroViewEdit() {
  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);
  // プロフィール取得
  const { resultGetProfile, getProfile, isLoadingProfile, isErrorGetProrile } =
    useGetProfile();
  // プロフィール設定
  const { resultSetProfile, setProfile, isSendingProfile, isErrorSetProfile } =
    useSetProfile();
  const [textIntroductionWord, setTextIntroductionWord] = useState("");

  const [readOnlyState, setReadOnlyState] = useState(true);
  const editIntroductionSentence = () => {
    if (readOnlyState) {
      setReadOnlyState(false);
      refTextarea.current.focus();
    } else {
      inputedCheck();
    }
  };
  const [showModalOverWord, setShowModalOverWord] = useState(false);
  const [showModalSensitiveWord, setShowModalSensitiveWord] = useState(false);
  const refTextarea = useRef<HTMLTextAreaElement>(null!);

  // 遷移元からのユーザー情報を取得したとき
  const navigate = useNavigate();
  useEffect(() => {
    if (userInfo === undefined || userInfo === null) return;
    // プロフィール取得
    getProfile(userInfo?.id!);
  }, [userInfo]);

  // 登録済みプロフィールを取得したとき
  useEffect(() => {
    if (resultGetProfile === undefined || resultGetProfile === null) return;

    if (resultGetProfile.result_code === "0") {
      setTextIntroductionWord(resultGetProfile.result.introduction);
    } else if (resultGetProfile.result_code === "1") {
      console.log(resultGetProfile.message);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultGetProfile.message);
    }
  }, [resultGetProfile]);

  // 保存ボタンが押下されたとき
  const inputedCheck = () => {
    if (textIntroductionWord.length > 200) {
      setShowModalOverWord(true);
    } else {
      // プロフィール設定
      const condition: UserProfile = {
        user_id: userInfo?.id!,
        introduction: textIntroductionWord,
      };
      setProfile(condition);
    }
  };

  // プロフィール設定結果を受信したとき
  useEffect(() => {
    if (resultSetProfile === undefined || resultSetProfile === null) return;

    if (resultSetProfile.result_code === "1") {
      console.log(resultGetProfile?.message);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultSetProfile.message);
    } else if (resultSetProfile.result_code === "2") {
      // バックエンドで不適切語句を検出したとき
      setShowModalSensitiveWord(true);
    } else {
      // console.log(textIntroductionWord);
      // navigate("../mainMenu");
      navigate(process.env.REACT_APP_PATH_PREFIX + "mainMenu");
    }
  }, [resultSetProfile]);

  // 戻るアイコンがタップされたとき
  const handlerOnClickBackArrow = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "mainMenu");
  };

  return (
    <>
      <div className={css.coopbbs_block_wrap}>
        <div className={css.registInputboxWrap}>
          <div className={css.backArrow} onClick={handlerOnClickBackArrow}>
            <img src={backArrow} width="23" alt="" />
          </div>
          <div className={css.requester_nameProfile}>
            {/* <div className="requester_nameIcon">
              <Component_PersonalIcon />
            </div> */}

            <span>{userInfo?.nickname}</span>
          </div>
          <button
            className={`${css["coopbbsButton-introduce"]} ${
              readOnlyState ? css.readonly : ""
            } ${isLoadingProfile ? css.disabled : ""}`}
            onClick={editIntroductionSentence}
            disabled={isLoadingProfile}
          >
            {readOnlyState ? "編集" : "保存"}
          </button>

          <div className={css.introduceTextbox}>
            200文字以内
            <textarea
              className={css.readonly}
              placeholder=""
              readOnly={readOnlyState}
              value={textIntroductionWord}
              onChange={(event) => setTextIntroductionWord(event.target.value)}
              ref={refTextarea}
            ></textarea>
            <div className={`${css.text_14} ${css.mg_t_10}`}>
              ニックネームはマイページより変更いただけます。
            </div>
          </div>
        </div>
      </div>
      <ErrorModalOverWordCount
        showFlag={showModalOverWord}
        setShowModalOverWord={setShowModalOverWord}
      />
      <ErrorModalSensitiveWord
        showFlag={showModalSensitiveWord}
        setShowModalSensitiveWord={setShowModalSensitiveWord}
      />
    </>
  );
}

export default IntroViewEdit;
