import { useCallback, useState } from "react";
import axios from "axios";
import GetApiUrl from "../getApiUrl";

export const useGetStatus = () => {
    const [resultGetStatus, setResultGetStatus] = useState<ResultGetStatus | undefined>();
    const [isLoadingStatus, setIsLoadingStatus] = useState<boolean>(false);
    const [isErrorGetStatus, setIsErrorGetStatus] = useState<boolean>(false);

    const assistSvr = axios.create({
        baseURL: GetApiUrl(),
    });

    const getStatus = useCallback(async (user_id: string) => {
        // console.dir(user_id);
        setIsLoadingStatus(true);
        setIsErrorGetStatus(false);

        await assistSvr
            .get(`/thread/getStatus?user_id=${user_id}`)
            .then((response) => {
                // console.dir(response.data);
                setResultGetStatus(response.data);
            })
            .catch((e) => {
                setIsErrorGetStatus(true);
                console.dir(e);
                process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
                setResultGetStatus({
                    result_code: "9",
                    message: "システムエラー",
                    result: [{
                        user_id: user_id,
                        new_assist_request_count: "0",
                        new_assist_request_chat_count: "0",
                        new_my_help_chat_count: "0",
                    }]
                })
            })
            .finally(() => {
                setIsLoadingStatus(false);
            });
    }, []);
    return { resultGetStatus, getStatus, isLoadingStatus, isErrorGetStatus };
};

export type Status = {
    user_id: string,
    new_assist_request_count: string,
    new_assist_request_chat_count: string,
    new_my_help_chat_count: string
}

export type ResultGetStatus = {
    result_code: string;    // 処理結果コード
    message: string;        // エラーメッセージ
    result: Status[];         // ステータス
};
