import axios from "axios";
import { useState } from "react";
import GetApiUrl from "../getApiUrl";

// ありが隊申し込みのキャンセル
export const useCancelThanks = () => {
    const [resultCancelThanksRegist, setResultThanksRegist] = useState<ResultData | undefined>();
    const [isLoadingCancelThanksRegist, setIsLoadingCancelThanksRegist] = useState<boolean>(false);
    const [isErrorCancelThanksRegist, setIsErrorThanksRegist] = useState<boolean>(false);

    const assistSvr = axios.create({
        baseURL: GetApiUrl(),
    });

    const sendCancelThanksRegist = async (props: ConditionCancelThanks) => {
        // console.dir(props);
        setIsLoadingCancelThanksRegist(true);
        setIsErrorThanksRegist(false);

        assistSvr.post("/thread/cancelThanks", {
            ...props
        }).then((response) => {
            // console.dir(response.data);
            setResultThanksRegist(response.data);
        }).catch((e) => {
            console.dir(e);
            setIsErrorThanksRegist(true);
            process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
        }).finally(() => {
            setIsLoadingCancelThanksRegist(false);
        })
    };
    return { resultCancelThanksRegist, sendCancelThanksRegist, isLoadingCancelThanksRegist, isErrorCancelThanksRegist };
};

// 助け隊キャンセル
export type ConditionCancelThanks = {
    id: number;       // 助け隊登録ID
    user_id: string;  // ログインユーザのめぶくID
}

export type ResultData = {
    result_code: string;    // 処理結果コード
    message: string;        // エラーメッセージ
};
