import axios from "axios";
import GetApiUrl from "../getApiUrl";

const assistSvr = axios.create({
    baseURL: GetApiUrl()
});

// 助け隊検索
export const searchSupportList = async (props: ConditionSupportList) => {
    // console.log("called: searchSupportList page= " + props.page);
    // console.dir(props);

    return await assistSvr.post('/thread/searchSupporter', {
        ...props
    }).then((response) => {
        // console.dir(response.data);
        return response;
    });
}

export type ConditionSupportList = {
    user_id: string;
    owner_class: number;
    // support_date: string;
    // time_from: string;
    // category: string[];
    // target: string[];
    // appoint: string[];
    // alliliation: number;
    page: number;
    recruitment_flag: number;
};

export type Support = {
    id: number;
    supporter_id: string;
    title: string;
    description: string;
    area_choice: string[];
    area_sentense: string;
    supporter_date: string;
    begin_time: string;
    end_time: string;
    category: string[];
    target: string[];
    appoint: string[];
    alliliation: number;
    corporate_name: string;
    corporate_kana: string;
    route_from: string;
    route_to: string;
    number_peoples: number;
};

export type SupportList = {
    result_code: string;
    message: string;
    result: Support[];
    count: number;
    page: number;
    page_total: number;
};


export const initialCondition: ConditionSupportList = {
    user_id: "",
    owner_class: 0,
    // support_date: "",
    // time_from: "",
    // category: [],
    // target: [],
    // appoint: [],
    // alliliation: 0,
    page: 0,
    recruitment_flag: 0
};

export const initialSupport: Support = {
    id: 0,
    supporter_id: "",
    title: "",
    description: "",
    area_choice: [],
    area_sentense: "",
    supporter_date: "",
    begin_time: "",
    end_time: "",
    alliliation: 0,
    category: [],
    target: [],
    appoint: [],
    corporate_name: "",
    corporate_kana: "",
    route_from: "",
    route_to: "",
    number_peoples: 0
}

export const initialSupportList: SupportList = {
    result_code: "",
    message: "",
    result: [],
    count: 0,
    page: 0,
    page_total: 0
};