import axios from "axios";
import { useState } from "react";
import GetApiUrl from "../getApiUrl";

// NGワードチェック
export const useCheckNGWord = () => {
    const [resultCheckNgWord, setResultCheckNgWord] = useState<ResultCheckNgWord | undefined>();
    const [isLoadingCheckNgWord, setIsLoadingCheckNgWord] = useState<boolean>(false);
    const [isErrorCheckNgWord, setIsErrorCheckNgWord] = useState<boolean>(false);

    const assistSvr = axios.create({
        baseURL: GetApiUrl(),
    });

    const checkNgWord = async (props: ConditionCheckNgWord) => {
        // console.dir(props);
        setIsLoadingCheckNgWord(true);
        setIsErrorCheckNgWord(false);

        assistSvr.post("/master/checkNgWord", {
            ...props
        }).then((response) => {
            // console.dir(response.data);
            setResultCheckNgWord(response.data);
        }).catch((e) => {
            console.dir(e);
            setIsErrorCheckNgWord(true);
            process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
        }).finally(() => {
            setIsLoadingCheckNgWord(false);
        })
    };

    return { resultCheckNgWord, checkNgWord, isLoadingCheckNgWord, isErrorCheckNgWord };
};

// NGワードチェック条件
export type ConditionCheckNgWord = {
    user_id: string;
    description: string;
}

export type ResultCheckNgWord = {
    result_code: string;    // 
    message: string;        // エラーメッセージ
};
