import { useCallback, useState } from "react";
import axios from "axios";
import GetApiUrl from "../getApiUrl";

// 助け隊登録
export const useSetSupporter = () => {
    const [resultSetSupporter, setResultSetSupporter] = useState<ResultSetSupporter | undefined>();
    const [isSendingSupporter, setIsSendingSupporter] = useState<boolean>(false);
    const [isErrorSetSupporter, setIsErrorSetSupporter] = useState<boolean>(false);

    const assistSvr = axios.create({
        baseURL: GetApiUrl(),
    });

    const setSupporter = useCallback(async (props: SupporterSetData) => {
        // console.dir(props);
        setIsSendingSupporter(true);
        setIsErrorSetSupporter(false);

        assistSvr
            .post('/thread/setSupporter', {
                ...props
            })
            .then((response) => {
                // console.dir(response.data);
                setResultSetSupporter(response.data);
            })
            .catch((e) => {
                setIsErrorSetSupporter(true);
                console.dir(e);
                process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
            })
            .finally(() => {
                setIsSendingSupporter(false);
            });
    }, []);
    return { resultSetSupporter, setSupporter, isSendingSupporter, isErrorSetSupporter };
};

export type SupporterSetData = {
    id: number;                 // レコードID
    user_id: string;            // 利用者ID(めぶくID)
    title: string;              // タイトル
    description: string;        // 内容と詳細
    area_choice: string[];      // 活動地域（選択内容）
    area_sentense: string;      // 活動地域（フリーワード） 
    supporter_date: string;     // 活動日
    begin_time: string;         // 開始時間
    end_time: string;           // 終了時間
    alliliation: string;        // 所属（個人、法人）
    category: string[];         // カテゴリ
    target: string[];           // 助けられる対象
    appoint: string[];          // 待ち合わせ場所
    corporate_name: string;     // （「法人」選択時）法人名
    corporate_kana: string;     // （「法人」選択時）法人名の読み仮名
    route_from: string;         // 出発地
    route_to: string;           // 目的地
};

export type ResultSetSupporter = {
    result_code: string;    // 
    message: string;        // エラーメッセージ
};
