import React, { useState, useEffect, useContext } from "react";
import css from "./IntroRegist.module.css";
import { useNavigate } from "react-router-dom";
import ErrorModalOverWordCount from "../../components/ErrorModal/ErrorModalOverWordCountTwoHundred";
import ErrorModalSensitiveWord from "../../components/ErrorModal/ErrorModalSensitiveWord";
import type { UserProfile } from "../../components/apiAccess/profile/useSetProfile";
import { useSetProfile } from "../../components/apiAccess/profile/useSetProfile";
import { UserInfoContext } from "../../components/providers/userInfoProvider";

function IntroRegist() {
  const navigate = useNavigate();
  // プロフィール設定
  const { resultSetProfile, setProfile, isSendingProfile, isErrorSetProfile } =
    useSetProfile();
  // ログインユーザ情報
  const { userInfo } = useContext(UserInfoContext);

  // useEffect(() => {
  //   // ユーザー情報の取得確認
  //   console.dir(userInfo);
  // }, [userInfo]);

  // 「自己紹介を登録する」タップしたとき
  const inputedCheck = () => {
    if (textIntroductionWord.length > 200) {
      setShowModalOverWord(true);
    } else {
      const condition: UserProfile = {
        user_id: userInfo?.id!,
        introduction: textIntroductionWord,
      };

      // プロフィール設定
      setProfile(condition);
    }
  };

  // プロフィール設定の結果が返ってきたとき
  useEffect(() => {
    if (resultSetProfile === undefined) {
      return;
    } else if (resultSetProfile.result_code === "0") {
      // console.log(textIntroductionWord);
      // navigate("../mainMenu");
      navigate(process.env.REACT_APP_PATH_PREFIX + "mainMenu");
    } else if (resultSetProfile.result_code === "1") {
      console.log(resultSetProfile.message);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultSetProfile.message);
    } else if (resultSetProfile.result_code === "2") {
      setShowModalSensitiveWord(true);
    }
  }, [resultSetProfile]);

  const [textIntroductionWord, setTextIntroductionWord]: any = useState("");
  const [showModalOverWord, setShowModalOverWord] = useState(false);
  const [showModalSensitiveWord, setShowModalSensitiveWord] = useState(false);
  const SendButton = () => {
    if (textIntroductionWord.length === 0) {
      return (
        <div>
          <div className={`${css.coopbbsButton_wrap} ${css.column}`}>
            <div
              className={`${css.coopbbsButton} ${css.gfs_color_gray} ${css.size_l}`}
            >
              自己紹介を登録する
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div
            className={`${css.coopbbsButton_wrap} ${css.column}`}
            onClick={inputedCheck}
          >
            <div
              className={`${css.coopbbsButton} ${css.color_pink} ${css.size_l}`}
            >
              自己紹介を登録する
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <article className={`${css.content_all} ${css.initialize_back}`}>
        <div className={`${css.content_inner} ${css.article_margin}`}>
          <div className={css.coopbbs_block_wrap}>
            <div className={css.introductionTitle}>
              自己紹介を書いてみましょう。
            </div>
            <hr className={css.divider} />
            <p
              className={`${css.mg_t_10} ${css.gfs_text_initial_display_example_sentence}`}
            >
              お互い安心して助け合いができるよう、あなたの人柄を教えてください。
            </p>
            <div className={css.registTextbox}>
              <div className={css["registTextbox_title-wrap"]}>
                <span className={css.gfs_requireTextTitle}>（必須）</span>
                <span className={css.requireText}>200文字以内</span>
              </div>
              <textarea
                className={css.gfs_text_area}
                onChange={(event) =>
                  setTextIntroductionWord(event.target.value)
                }
              ></textarea>
            </div>
            <div className={`${css.mg_t_20} ${css.text_14}`}>
              <div className={css.gfs_text_initial_display}>
                <p>
                  例文
                  <div>
                    こんにちは〇〇です。小中学生3人の母親です。料理好きで特にスイーツを作るのが得意です。性格は明るい方だと思います!
                  </div>
                </p>
                <p>
                  <div
                    className={css.gfs_text_initial_display_example_sentence}
                  >
                    はじめまして〇〇と申します。平日は会社帰りにジムに行くのが日課になっています。週末は友達とキャンプに行くことが多いです。
                  </div>
                </p>
              </div>
            </div>
            <div className={css.mg_t_30}>
              <SendButton />
            </div>
          </div>
        </div>
      </article>
      <ErrorModalOverWordCount
        showFlag={showModalOverWord}
        setShowModalOverWord={setShowModalOverWord}
      />
      <ErrorModalSensitiveWord
        showFlag={showModalSensitiveWord}
        setShowModalSensitiveWord={setShowModalSensitiveWord}
      />
    </>
  );
}

export default IntroRegist;
