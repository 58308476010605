import { useCallback, useState } from "react";
import axios from "axios";
import GetApiUrl from "../getApiUrl";

export const useSetAccount = () => {
  const [resultSetAccount, setResultSetAccount] = useState<
    ResultSetAccount | undefined
  >();
  const [isSendingAccount, setIsSendingAccount] = useState<boolean>(false);
  const [isErrorSetAccount, setIsErrorSetAccount] = useState<boolean>(false);

  const assistSvr = axios.create({
    baseURL: GetApiUrl(),
  });

  const setAccount = useCallback(async (props: AccountInfo) => {
    // console.dir(props);
    setIsSendingAccount(true);
    setIsErrorSetAccount(false);

    await assistSvr
      .post(`/account/setAccount`, {
        ...props,
      })
      .then((response) => {
        // console.dir(response.data);
        setResultSetAccount(response.data);
      })
      .catch((e) => {
        setIsErrorSetAccount(true);
        console.dir(e);
        process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
      })
      .finally(() => {
        setIsSendingAccount(false);
      });
  }, []);
  return { resultSetAccount, setAccount, isSendingAccount, isErrorSetAccount };
};

export type AccountInfo = {
  user_id: string; // めぶくID
  id_type: string;
  nickname: string;
  mail_address: string;
  version: string;
  client_id: string;
};

export type ResultSetAccount = {
  result_code: string; //
  message: string; // エラーメッセージ
};
