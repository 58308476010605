import axios from "axios";
import GetApiUrl from "../getApiUrl";

const assistSvr = axios.create({
    baseURL: GetApiUrl(),
});

export const searchThanks = async (props: ConditionSearchThanks) => {
    // console.log("called: searchThanks page= " + props.page);
    // console.dir(props);

    return await assistSvr.post('/thread/searchThanks', {
        ...props
    }).then((response) => {
        // console.dir(response.data);
        return response;
    });

}

export type ConditionSearchThanks = {
    user_id: string,
    owner_class: number,
    matching_class: number,
    id: number,
    order_column: number,
    order: number,
    page: number,
}

export type Thanks = {
    id: string,
    supporter_id: string,
    title: string,
    nickname: string,
    introduction: string,
    comment: string,
    supporter_date: string,
    request_datetime: string,
    begin_time: string,
    end_time: string,
    chat_unread: string,
}

export type thanksList = {
    result_code: string;
    message: string;
    result: Thanks[];
    count: number;
    page: number;
    page_total: number;
};

