import { useCallback, useState } from "react";
import axios from "axios";
import GetApiUrl from "../getApiUrl";

// 助け合い完了設定
export const useSupportComplete = () => {
    const [resultSendComplete, setResultSendComplete] = useState<ResultData | undefined>();
    const [isSendingComplete, setIsSendingComplete] = useState<boolean>(false);
    const [isErrorSendComplete, setIsErrorSendComplete] = useState<boolean>(false);

    const assistSvr = axios.create({
        baseURL: GetApiUrl(),
    });

    const sendComplete = useCallback(async (props: ConditionComplete) => {
        // console.dir(props);
        setIsSendingComplete(true);
        setIsErrorSendComplete(false);

        assistSvr
            .post(`/thread/complete`, {
                ...props
            })
            .then((response) => {
                // console.dir(response.data);
                setResultSendComplete(response.data);
            })
            .catch((e) => {
                setIsErrorSendComplete(true);
                console.dir(e);
                process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
            })
            .finally(() => {
                setIsSendingComplete(false);
            });
    }, []);
    return { resultSendComplete, sendComplete, isSendingComplete, isErrorSendComplete };
};

// 助け合い完了設定パラメータ
export type ConditionComplete = {
    user_id: string;    // ログインユーザのめぶくID
    id: number;         // ありが隊側user_id
};

export type ResultData = {
    result_code: string;    // 
    message: string;        // エラーメッセージ
};
