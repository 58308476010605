import React, {
  Dispatch,
  SetStateAction,
  memo,
  useEffect,
  useState,
} from "react";
import { SupporterSetData } from "../../components/apiAccess/thread/useSetSupporter";
import css from "./DateTime.module.css";
import getToday from "./Method_getToday";

type DateTimeType = {
  inputDate: string;
  inputStartTime: string;
  inputEndTime: string;
};

interface DateTimeProps {
  supporterData: SupporterSetData;
  setSupporterData: Dispatch<SetStateAction<SupporterSetData>>;
  setInputMode: Dispatch<SetStateAction<string>>;
}

// 日時
const DateTime = memo((props: DateTimeProps) => {
  const { supporterData, setSupporterData, setInputMode } = props;

  // 仮設定値
  const getDateTimeData: DateTimeType = {
    inputDate: supporterData.supporter_date,
    inputStartTime: supporterData.begin_time,
    inputEndTime: supporterData.end_time,
  };

  let ymd: any = new Date();
  let todayDate = getToday((ymd = { ymd }));

  const [dateTimeData, setDateTimeData] =
    useState<DateTimeType>(getDateTimeData);
  const [isNoDate, setIsNoDate] = useState<boolean>(false);
  const [isPastDate, setIsPastDate] = useState<boolean>(false);
  const [isAbnormalEndTime, setIsAbnormalEndTime] = useState<boolean>(false);
  const [isNoStartTime, setIsNoStartTime] = useState<boolean>(false);
  const [isNoEndTime, setIsNoEndTime] = useState<boolean>(false);

  const [isNormalDate, setIsNormalDate] = useState<boolean>(true);
  const [isNormalStartTime, setIsNormalStartTime] = useState<boolean>(true);
  const [isNormalEndTime, setIsNormalEndTime] = useState<boolean>(true);

  const [enableNext, setEnableNext] = useState<boolean>(false);

  const setDateTime = () => {
    let inputdateByCalendar = dateTimeData.inputDate.slice(0, 10);

    if (todayDate > inputdateByCalendar && inputdateByCalendar) {
      // console.log("Error_過去の日付が入力されています");
      setIsPastDate(true);
      setIsNormalDate(false);
    } else {
      // console.log("OK_本日以降の日付が入力されています");
      if (
        dateTimeData.inputStartTime >= dateTimeData.inputEndTime &&
        dateTimeData.inputEndTime
      ) {
        console.log("Error_終了時間は開始時間以降を設定しなければなりません");
        setIsAbnormalEndTime(true);
        setIsNormalEndTime(false);
      } else {
        // console.log("OK_開始時間と終了時間は正しく入力されています");
        if (
          dateTimeData.inputDate &&
          dateTimeData.inputStartTime &&
          dateTimeData.inputEndTime
        ) {
          //DateTimeDataを更新
          setSupporterData((prev: SupporterSetData) => ({
            ...prev,
            supporter_date: dateTimeData.inputDate,
            begin_time: dateTimeData.inputStartTime,
            end_time: dateTimeData.inputEndTime,
          }));
          setInputMode("助け隊登録");
        } else {
          if (!dateTimeData.inputDate) {
            console.log("Error_日付未入力");
            setIsNoDate(true);
            setIsNormalDate(false);
          }
          if (!dateTimeData.inputStartTime) {
            console.log("Error_開始時間入力");
            setIsNoStartTime(true);
            setIsNormalStartTime(false);
          }
          if (!dateTimeData.inputEndTime) {
            console.log("Error_終了時間入力");
            setIsNoEndTime(true);
            setIsNormalEndTime(false);
          }
        }
      }
    }
  };

  const onChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputDate = e.target.value;
    setDateTimeData((prev) => ({
      ...prev,
      inputDate: inputDate,
    }));
  };

  const onChangeStartTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputStartTime = e.target.value;
    setDateTimeData((prev) => ({
      ...prev,
      inputStartTime: inputStartTime,
    }));
  };
  const onChangeEndTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputEndTime = e.target.value;
    setDateTimeData((prev) => ({
      ...prev,
      inputEndTime: inputEndTime,
    }));
  };

  useEffect(() => {
    if (
      dateTimeData.inputDate !== "" &&
      dateTimeData.inputStartTime !== "" &&
      dateTimeData.inputEndTime !== ""
    ) {
      setEnableNext(true);
    } else {
      setEnableNext(false);
    }
  }, [dateTimeData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={css.registInputboxWrap}>
        <div className={css.registTextbox}>
          <div className={css["registTextbox_title-wrap"]}>
            <div>
              <span className={css.title}>助けられる日付</span>
            </div>
          </div>
          <input
            type="date"
            className={
              isNormalDate
                ? css.registInputText
                : css.gfs_textarea_sentence_errored
            }
            placeholder=""
            value={dateTimeData.inputDate}
            onChange={(e) => onChangeDate(e)}
          />
          {isNoDate && (
            <div className={css.error_msg}>
              助けられる日付を入力してください
            </div>
          )}
          {isPastDate && (
            <div className={css.error_msg}>過去の日付が入力されています</div>
          )}
        </div>
        <div className={css.registTextbox}>
          <div className={css["registTextbox_title-wrap"]}>
            <div>
              <span className={css.title}>開始できる時間</span>
            </div>
          </div>
          <input
            type="time"
            className={
              isNormalStartTime
                ? css.registInputText
                : css.gfs_textarea_sentence_errored
            }
            placeholder=""
            // disabled={props.buttonStateStart}
            value={dateTimeData.inputStartTime}
            onChange={(e) => onChangeStartTime(e)}
          />
          {isNoStartTime && (
            <div className={css.error_msg}>
              開始できる時間を入力してください
            </div>
          )}
        </div>
        <div className={css.registTextbox}>
          <div className={css["registTextbox_title-wrap"]}>
            <div>
              <span className={css.title}>終了時間</span>
            </div>
          </div>
          <input
            type="time"
            className={
              isNormalEndTime
                ? css.registInputText
                : css.gfs_textarea_sentence_errored
            }
            placeholder=""
            value={dateTimeData.inputEndTime}
            onChange={(e) => onChangeEndTime(e)}
          />
          {isNoEndTime && (
            <div className={css.error_msg}>終了時間を入力してください</div>
          )}
          {isAbnormalEndTime && (
            <div className={css.error_msg}>
              終了時間は開始時間以降を設定しなければなりません
            </div>
          )}
        </div>
      </div>
      <div className={`${css.coopbbsButton_wrap} ${css.column}`}>
        {enableNext && (
          <div
            className={`${css.coopbbsButton} ${css.color_pink} ${css.size_l}`}
            onClick={setDateTime}
          >
            つぎへ
          </div>
        )}
        {!enableNext && (
          <div
            className={`${css.coopbbsButton} ${css.color_disable} ${css.size_l}`}
          >
            つぎへ
          </div>
        )}
      </div>
    </>
  );
});

export default DateTime;
