import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserInfoContext } from "../../components/providers/userInfoProvider";
import { useGetMebukuEyeAll } from "../../components/apiAccess/mebukuEye/useGetMeubukuEyeAll";
import type { MebukuEyeData } from "../../components/apiAccess/mebukuEye/useGetMeubukuEyeAll";
import mebukuEyeLogo from "../../../../img/topmenu_mebukuEyeLogo.png";
import mebukueyeAppIcon from "../../../../img/mebukueyeAppIcon.png";
import backArrow from "../../../../img/backArrow.svg";
import { ReactComponent as NextArrowWhite } from "../../../../img/nextArrow-white.svg";
import css from "./IntroMebukuEye.module.css";

const IntroMebukuEye = () => {
  // ■戻るナビゲート
  const navigate = useNavigate();
  // ■取得しためぶくEYEのJoin履歴を格納する変数
  const [mebukuEyeList, setMebukuEyeList] = useState<MebukuEyeData[]>();

  // ■めぶくEYEのJoin履歴取得用のカスタムフックを実行し、各変数を取得
  const {
    resultGetMebukuEyeAll,
    getMebukuEyeAll,
    isLoadingMebukuEyeAll,
    isErrorGetMebukuEyeAll,
  } = useGetMebukuEyeAll();

  // ■ログインユーザ情報を取得
  const { userInfo } = useContext(UserInfoContext);

  // ■初回レンダリング実行
  // めぶくEYEのJoin履歴取得用の関数を実行
  useEffect(() => {
    if (userInfo === undefined || userInfo === null) return;

    // console.log("「めぶくEYE紹介」画面 初回レンダリング実行");
    // console.log(userInfo);
    const userId = userInfo?.id!;
    getMebukuEyeAll(userId);
  }, []);

  // ■APIで取得しためぶくEYEのJoin履歴を変数へ格納
  useEffect(() => {
    if (resultGetMebukuEyeAll === undefined || resultGetMebukuEyeAll === null)
      return;

    if (resultGetMebukuEyeAll?.result_code === "0") {
      setMebukuEyeList(resultGetMebukuEyeAll.result);
    }
  }, [resultGetMebukuEyeAll]);

  // ■戻るアイコンがタップされたとき
  const handlerOnClickBackArrow = () => {
    navigate(process.env.REACT_APP_PATH_PREFIX + "mainMenu");
  };

  // ■「アプリをダウンドードしよう！」ボタンがタップされたとき
  const moveToNativeApp = () => {
    let mebukuEyeApp = localStorage.getItem('mebuku_eye_app');
    if (mebukuEyeApp == null || mebukuEyeApp === '0') {
      mebukuEyeApp = '1';
    } else {
      mebukuEyeApp = '0';
    }
    localStorage.setItem('mebuku_eye_app', mebukuEyeApp);
    window.dispatchEvent( new StorageEvent('storage', {
      key: 'mebuku_eye_app',
      newValue: mebukuEyeApp
    }));
  }

  return (
    <>
      <article className={`${css.content_all} ${css.event_back}`}>
        <div className={css.coopbbs_block_wrap}>
          <div className={css.introMebukueye_message_block}>
            <div
              className={`${css.pageTitle} ${css["pageTitle_color-everyones"]}`}
            >
              <div className={css.backArrow} onClick={handlerOnClickBackArrow}>
                <img src={backArrow} width="23" alt="" />
              </div>
            </div>
            <img src={mebukuEyeLogo} alt="めぶくeye" width="190" />
            <div>
              <p>
              「めぶくEye」は、視覚障がい者に向けた生活サポートアプリです。
              </p>
              <p>
              こちらのページでは、「めぶくEye」で行われた手助け状況を掲載しています。ぜひダウンロードしてご利用ください。
              </p>
              <p className={css.text_14}>
                ※本アプリはiOS専用アプリとなります。
              </p>
            </div>
            <div
              className={css.introMebukueye_message_wrap}
              id="introMebukueye"
            >
              {mebukuEyeList &&
                mebukuEyeList.length > 0 &&
                mebukuEyeList[0].id !== "" &&
                mebukuEyeList.map((data: MebukuEyeData) => {
                  return (
                    <div className={css.introMebukueye_message} key={data.id}>
                      <div>{data.joined_at}</div>
                      <div>
                        <span className={css.place}>{data.area}</span>
                        {data.description}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className={css.downloadMebukuApp_fix}>
          <div className={css.flex_c}>
            <div 
              onClick={moveToNativeApp}
              className={css.downloadMebukuApp}>
              <img src={mebukueyeAppIcon} alt="めぶくeye" width="50" />
              <span className={`${css.text_16} ${css.line_h_ss}`}>
                アプリをダウンロードしよう！　
              </span>
              <NextArrowWhite />
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default IntroMebukuEye;
