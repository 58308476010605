import axios from "axios";
import { useState } from "react";
import GetApiUrl from "../getApiUrl";

// ありが隊許諾APIアクセス
export const useIssueThanks = () => {
    const [resultIssueThanks, setResultIssueThanks] = useState<ResultData | undefined>();
    const [isLoadingIssueThanks, setIsLoadingIssheThanks] = useState<boolean>(false);
    const [isErrorIssueThanks, setIsErrorIssueThanks] = useState<boolean>(false);

    const assistSvr = axios.create({
        baseURL: GetApiUrl(),
    });

    const sendIssueThanks = async (props: ConditionIssueThanks) => {
        // console.dir(props);
        setIsLoadingIssheThanks(true);
        setIsErrorIssueThanks(false);

        assistSvr.post("/thread/issueThanks", {
            ...props
        }).then((response) => {
            // console.dir(response.data);
            setResultIssueThanks(response.data);
        }).catch((e) => {
            console.dir(e);
            setIsErrorIssueThanks(true);
            process.env.REACT_APP_ALERT_ENABLE === "true" && alert(e);
        }).finally(() => {
            setIsLoadingIssheThanks(false);
        })
    };

    return { resultIssueThanks, sendIssueThanks, isLoadingIssueThanks, isErrorIssueThanks };
};

// ありが隊許諾API取得条件
export type ConditionIssueThanks = {
    id: number;                 // 助け隊登録ID
    user_id: string;            // ログインユーザのめぶくID
    request_user_id: string;    // 自分が登録した助け隊に共助をリクエストしてきたユーザーのめぶくID
}

export type ResultData = {
    result_code: string;    // 
    message: string;        // エラーメッセージ
};
