import axios from "axios";
import GetApiUrl from "../getApiUrl";

const assistSvr = axios.create({
    baseURL: GetApiUrl(),
});

export const getMessageAll = async (props: ConditionGetMessageAll) => {
    const { user_id, id, page } = props;
    // console.log("called: getMessageAll page= " + props.page);
    // console.dir(props);

    const url: string = `/chat/getMessageAll?user_id=${user_id}&id=${id}&page=${page}`
    return await assistSvr(url).then((response) => {
        // console.dir(response.data);
        return response;
    });

}

export type ConditionGetMessageAll = {
    user_id: string,
    id: number, // 自分がもしくは相手が登録した助け隊のレコードID
    page: number,
}

export type ChatItem = {
    user_id: string;
    message_no: string;
    date: string;
    message: string;
}

export type ChatList = {
    result_code: string;
    message: string;
    count: number;
    get: number;
    page_total: number;
    result: ChatItem[];
}

export const initialChatList: ChatList = {
    result_code: "0",
    message: "",
    count: 0,
    get: 0,
    page_total: 0,
    result: []
};