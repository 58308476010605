import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Intro0 from "../../../../img/coopbbs_intro-0.png";
import Intro1 from "../../../../img/coopbbs_intro-1.png";
import Intro2 from "../../../../img/coopbbs_intro-2.png";
import { UserInfoContext } from "../../components/providers/userInfoProvider";
import checkParameter from "../../components/providers/CheckUserInfo";
import type { ProfiledData } from "../../components/apiAccess/profile/useGetProfile";
import { useGetProfile } from "../../components/apiAccess/profile/useGetProfile";
import {
  AccountInfo,
  useSetAccount,
} from "../../components/apiAccess/account/useSetAccount";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import css from "./Assistance.module.css";
import Slider from "react-slick";
import type { ConditionActivate } from "../../components/apiAccess/event/useExecuteActivate";
import { useExecuteActivate } from "../../components/apiAccess/event/useExecuteActivate";
import { UserInfoType } from "../../components/providers/types";

function Assistance() {
  // クエリーパラメータを取得する
  const { search } = useLocation();
  // ログインユーザ情報
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  // プロフィール取得
  const { resultGetProfile, getProfile, isLoadingProfile, isErrorGetProrile } =
    useGetProfile();
  // プロフィール設定
  const { resultSetAccount, setAccount, isSendingAccount, isErrorSetAccount } =
    useSetAccount();
  // 新規ユーザか否か
  const [isFirstTime, setIsFirstTime] = useState<boolean>(false);
  // イベントに参加するカスタムフック
  const {
    resultActivate,
    executeActivate,
    isSendingActivate,
    isErrorActivate,
  } = useExecuteActivate();

  const navigate = useNavigate();

  const refSlider = useRef<Slider>(null);
  const [buttonTitle, setButtonTitle] = useState<string>("つぎへ");
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [showInitial, setShowInitial] = useState<boolean>(false);

  // 初回実行
  useEffect(() => {
    const queryParam = new URLSearchParams(search);
    const user = checkParameter(queryParam);

    if (
      user === null ||
      user === undefined ||
      user.id === null ||
      user.id === ""
    ) {
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert("パラメータが不足しています");
      console.log("ユーザー情報不足のため処理中断");
      return;
    } else {
      setUserInfo(user);
    }
    // プロフィール取得
    getProfile(user.id);
  }, []);

  // プロフィール取得結果が返ってきたとき
  useEffect(() => {
    if (resultGetProfile === undefined) return;
    if (resultGetProfile.result_code === "0") {
      // 正常のとき
      const user: UserInfoType = {
        id_kind: resultGetProfile.result.id_type,
        id: resultGetProfile.result.user_id,
        nickname: resultGetProfile.result.nickname,
        mail: resultGetProfile.result.mail_address,
        version: resultGetProfile.result.version,
        client_id: resultGetProfile.result.client_id,
      };
      setUserInfo(user);

      // console.log("ユーザー登録あり");
      // ユーザ登録済み
      if (checkUserInfo(resultGetProfile.result)) {
        // console.log("めぶくID以外のユーザー情報も一致");
        if (
          resultGetProfile.result.introduction !== null &&
          resultGetProfile.result.introduction !== ""
        ) {
          // console.log("プロフィール登録済み、メインメニューに遷移");
          // navigate("../mainMenu");
          navigate(process.env.REACT_APP_PATH_PREFIX + "mainMenu");
        } else {
          setShowInitial(true);
          // console.log("プロフィール未登録、本画面継続");
        }
      } else {
        setShowInitial(true);
        // console.log("めぶくID以外のユーザー情報が不一致、ユーザー情報更新");
        // 不一致情報がある場合は、更新する
        registUpdateUser();
      }
    } else if (
      resultGetProfile.result_code === "1" ||
      resultGetProfile.result_code === "9"
    ) {
      setShowInitial(true);
      // 異常のとき
      console.log(resultGetProfile.message);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultGetProfile.message);

      // ユーザ未登録。新規登録する。
      setIsFirstTime(true);
      registUpdateUser();
      registCoopbbs();
    }
  }, [resultGetProfile]);

  // ユーザー情報の登録／更新
  const registUpdateUser = () => {
    const account: AccountInfo = {
      user_id: userInfo?.id!,
      id_type: userInfo?.id_kind!,
      nickname: userInfo?.nickname!,
      mail_address: userInfo?.mail!,
      version: userInfo?.version!,
      client_id: userInfo?.client_id!,
    };
    setAccount(account);
  };

  // 助け合いに登録
  const registCoopbbs = () => {
    const conditionActivate: ConditionActivate = {
      account_id: userInfo?.id!,
      activation_cd: "",
      event_id: "2", // 助け合いのイベントID
    };
    executeActivate(conditionActivate);
  };

  // ユーザー情報の登録／更新結果を受信したとき
  useEffect(() => {
    if (resultSetAccount === undefined || resultSetAccount === null) return;

    if (resultSetAccount.result_code === "0") {
      // console.log("ユーザー情報の登録／更新 完了");
      if (isFirstTime) {
        // プロフィール取得
        getProfile(userInfo?.id!);
      } else {
        // console.log("メインメニューに遷移");
        navigate(process.env.REACT_APP_PATH_PREFIX + "/mainMenu");
      }
    } else {
      // console.log("ユーザー情報の登録／更新 失敗");
      console.log(resultSetAccount.message);
      process.env.REACT_APP_ALERT_ENABLE === "true" &&
        alert(resultSetAccount.message);
    }
  }, [resultSetAccount]);

  const checkUserInfo = (prof: ProfiledData): boolean => {
    if (prof.id_type !== userInfo?.id_kind) return false;
    // if (prof.user_id !== userInfo?.id) return false;
    if (prof.nickname !== userInfo?.nickname) return false;
    if (prof.mail_address !== userInfo?.mail) return false;
    if (prof.version !== userInfo?.version) return false;
    if (prof.client_id !== userInfo?.client_id) return false;

    return true;
  };

  const settings = {
    accessibility: true,
    // lazyLoad: "ondemand", // 遅延読み込み
    autoplay: false, // 自動再生（初期設定：false）
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, // 次へ・前への表示非表示（初期設定：true）
    centerMode: false, // 前後のスライド表示させる
    centerPadding: "50px",
    // pauseOnFocus: true, //フォーカス時のスライダーの停止（初期設定：true）
    // pauseOnHover: true, //ホバー時のスライダーの停止（初期設定：true）
    //autoplaySpeed: 4000,  // 自動再生時のスライドのスピード（初期設定：3000）
    // dots: true,
    // infinite: false,
    // speed: 500,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // arrow: false,
  };

  const next = () => {
    refSlider?.current?.slickNext();
    setPageNumber((prev) => prev + 1);

    if (pageNumber === 3) {
      // ボタン名が「スタート」の次は、自己紹介登録画面に遷移する
      navigate(process.env.REACT_APP_PATH_PREFIX + "introRegist");
    }
  };

  useEffect(() => {
    if (pageNumber === 3) {
      setButtonTitle("スタート");
    }
  }, [pageNumber]);

  return (
    <>
      {showInitial && (
        <article
          className={`${css.content_all} ${css.event_back} ${css.side_padding}`}
        >
          <div
            className={`${css["coopbbs_intro-slick"]} ${["single-item"]} ${[
              "js-slickImage",
            ]}`}
          >
            <Slider ref={refSlider} {...settings}>
              <div className={css["coopbbs_intro-wrap"]}>
                <div className={css["coopbbs_intro-title"]}>
                  ようこそ
                  <br />
                  助け合い掲示板へ
                </div>
                <div>
                  助け合い掲示板は、「困っている人」と「助けたい人」をつなげるための掲示板です。
                </div>
                <div className={css.image_layout}>
                  <img src={Intro0} width="210" height="210" alt="" />
                </div>
              </div>
              <div className={css["coopbbs_intro-wrap"]}>
                <div className={css["coopbbs_intro-title"]}>
                  助け隊になって
                  <br />
                  人を助ける
                </div>
                <div>
                  助け隊を登録すると、あなたの「役に立ちたい」「助けたい」を発信できます。
                  <br />
                  <br />
                </div>
                <div className={css.image_layout}>
                  <img src={Intro1} width="210" height="210" alt="" />
                </div>
              </div>
              <div className={css["coopbbs_intro-wrap"]}>
                <div className={css["coopbbs_intro-title"]}>
                  助け隊を探して
                  <br />
                  助けてもらう
                </div>
                <div>
                  助けが必要な時や困った時はありが隊として、助け隊を探して助けてもらえます。
                  <br />
                  <br />
                </div>
                <div className={css.image_layout}>
                  <img src={Intro2} width="210" height="210" alt="" />
                </div>
              </div>
            </Slider>
          </div>
          <div className={`${css.content_inner} ${css.article_margin}`}>
            <div className={css.coopbbs_block_wrap}>
              <div
                className={`${css.coopbbsButton_wrap} ${css.column}`}
                onClick={next}
              >
                <div
                  className={`${css.coopbbsButton} ${css.color_pink} ${css.size_l}`}
                >
                  {buttonTitle}
                </div>
              </div>
            </div>
          </div>
        </article>
      )}
    </>
  );
}

export default Assistance;
